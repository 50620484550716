<template>
  <div class="has-nav-bar view-workticket-process">
    <WorkticketHeader title="流程"></WorkticketHeader>
    <van-tabs v-model="activeName" @change="tabsChange">
      <van-tab title="流程图" name="process">
        <process-nodes ref="processNodes" :ticket-id="id" />
      </van-tab>
      <van-tab title="作业记录" name="work">
        <div class="steps-box">
          <van-steps
            direction="vertical"
            :active="active"
            active-color="#1676FF"
          >
            <van-step v-for="(item, index) in list" :key="index">
              <template #inactive-icon>
                <i
                  :class="item.current === 1 ? 'active-icon' : 'inactive-icon'"
                ></i>
              </template>
              <template #active-icon>
                <i class="active-icon"></i>
              </template>

              <div class="process-item">
                <div class="title-line">
                  <span v-if="item.operation === '转审'" class="right"
                    >{{ item.operation
                    }}{{ `${item.nodeName ? "-" + item.nodeName : ""}` }}</span
                  >
                  <span
                    v-else-if="
                      item.operation
                        ? item.operation.slice(0, 2) === '审批'
                        : ''
                    "
                    class="right"
                    >{{ item.operation }}
                    {{ item.specialName ? `(${item.specialName})` : "" }}</span
                  >
                  <span v-else class="right">{{ item.operation }}</span>
                </div>
                <div v-if="item.operation === '转审'" class="modify-person-box">
                  <div class="inline-block">
                    转审前人员：
                    <span class="name-box">
                      {{
                        item.beforeTransferList
                          ? item.beforeTransferList.join(" ")
                          : ""
                      }}
                    </span>
                    转审后人员：
                    <span class="name-box">
                      {{
                        item.afterTransferList
                          ? item.afterTransferList.join(" ")
                          : ""
                      }}
                    </span>
                  </div>
                  <div v-if="countersignObj[item.countersign]">
                    <span>审批方式：</span>
                    <span class="name-icon-box">
                      {{ countersignObj[item.countersign] }}
                    </span>
                  </div>
                </div>
                <div v-if="item.nodeName">
                  <span>审批节点：</span>
                  <span class="name-icon-box">
                    {{ item.nodeName }}
                  </span>
                </div>
                <div
                  v-if="
                    item.operation ? item.operation.slice(0, 2) === '审批' : ''
                  "
                  class="modify-person-box"
                >
                  <div v-if="countersignObj[item.countersign]">
                    <span>审批方式：</span>
                    <span class="name-icon-box">
                      {{ countersignObj[item.countersign] }}
                    </span>
                  </div>
                  <div v-if="item.current !== 1">
                    <span>审批人：</span>
                    <span
                      class="name-icon-box"
                      @click="showSignature(item.signatureFileId)"
                    >
                      <span>{{ item.cuserName }}</span>
                      <i
                        v-if="item.operation !== '审批-退回'"
                        class="iconfont iconfont-icoqianming qianming"
                      ></i>
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    Array.isArray(item.modifyList) && item.modifyList.length
                  "
                  class="modify-person-box"
                >
                  <div class="inline-block">
                    变更前人员：
                    <span
                      v-for="item of item.modifyList.filter(
                        item => !item.current
                      )"
                      :key="item.id"
                      class="name-box"
                    >
                      {{ item.name }}
                    </span>
                    变更后人员：
                    <span
                      v-for="item of item.modifyList.filter(
                        item => item.current
                      )"
                      :key="item.id"
                      class="name-box"
                    >
                      {{ item.name }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    Array.isArray(item.deliverList) && item.deliverList.length
                  "
                  class="modify-person-box"
                >
                  <div>
                    <span>验收人：</span>
                    <span
                      v-for="item of item.deliverList"
                      :key="item.id"
                      class="name-icon-box"
                      @click="showSignature(item.fileId)"
                    >
                      <span>{{ item.name }}</span>
                      <i class="iconfont iconfont-icoqianming qianming"></i>
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    Array.isArray(item.workerList) && item.workerList.length
                  "
                  class="modify-person-box"
                >
                  <template v-if="typeMap[item.operationType]">
                    <div>
                      <span v-if="item.operation === '继续作业'">
                        确认人：
                      </span>
                      <span v-else-if="item.workerList[0].workerType === '40'">
                        交出人：
                      </span>
                      <span v-else>{{
                        `${typeMap[item.operationType]}人：`
                      }}</span>
                      <span
                        v-for="item of item.workerList"
                        :key="item.id"
                        class="name-icon-box"
                        @click="showSignature(item.fileId)"
                      >
                        <span>{{ item.name }}</span>
                        <i class="iconfont iconfont-icoqianming qianming"></i>
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      <div v-if="workHeadPerson(item.workerList)">
                        <span>作业负责人：</span>
                        <span
                          v-for="item of workHeadPerson(item.workerList)"
                          :key="item.id"
                          class="name-icon-box"
                          @click="showSignature(item.fileId)"
                        >
                          <span>{{ item.name }}</span>
                          <i class="iconfont iconfont-icoqianming qianming"></i>
                        </span>
                      </div>
                      <div v-if="approverPerson(item.workerList)">
                        <span>批准人：</span>
                        <span
                          v-for="item of approverPerson(item.workerList)"
                          :key="item.id"
                          class="name-icon-box"
                          @click="showSignature(item.fileId)"
                        >
                          <span>{{ item.name }}</span>
                          <i class="iconfont iconfont-icoqianming qianming"></i>
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
                <div
                  v-if="
                    Array.isArray(item.closerList) && item.closerList.length
                  "
                  class="modify-person-box"
                >
                  <div>
                    <span>关闭人：</span>
                    <span
                      v-for="item of item.closerList"
                      :key="item.id"
                      class="name-icon-box"
                      @click="showSignature(item.fileId)"
                    >
                      <span>{{ item.name }}</span>
                      <i class="iconfont iconfont-icoqianming qianming"></i>
                    </span>
                  </div>
                </div>
                <div
                  class="desc"
                  :class="{ 'initial-height': item.isShowAllDesc }"
                >
                  {{ item.remark }}
                  <span
                    class="ellipsis-box show-all-btn"
                    @click="showDescClick(true, index)"
                    >展开</span
                  >
                  <span class="after"></span>
                </div>
                <p v-show="item.isShowAllDesc" class="packup-box">
                  <span
                    class="show-all-btn"
                    @click="showDescClick(false, index)"
                    >收起</span
                  >
                </p>
                <div v-if="item.delayTimeInfo">
                  从{{ item.delayTimeInfo.start }} 延期至
                  {{ item.delayTimeInfo.end }}
                </div>
                <p class="time">
                  <span class="icon-item"
                    ><i
                      :style="
                        `background-image: url(${require('@/assets/images/workticket-item-5.png')})`
                      "
                    ></i
                    >{{ item.cuserName }}</span
                  >
                  <span v-if="item.ctime" class="icon-item"
                    ><i
                      :style="
                        `background-image: url(${require('@/assets/images/workticket-item-3.png')})`
                      "
                    ></i
                    >{{ item.ctime }}</span
                  >
                </p>
              </div>
            </van-step>
          </van-steps>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import WorkticketHeader from "@/components/header/WorkticketHeader";
import { getWorkticketProcess } from "@/api/workticket";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
import ProcessNodes from "./components/ProcessNodes";
export default {
  name: "WorkticketProcess",
  components: {
    WorkticketHeader,
    ProcessNodes
  },
  data() {
    return {
      countersignObj: {
        0: "或签",
        1: "会签"
      },
      id: "",
      active: 99999,
      list: [],
      activeName: "process",
      typeMap: {
        17: "验票",
        19: "监护",
        20: "停电",
        22: "作业负责",
        27: "作业地点负责"
      }
    };
  },
  created: function() {},
  mounted: function() {
    let { id } = this.$route.params;
    this.id = id;
    this.getWorkticketProcess();
  },
  methods: {
    tabsChange(name, title) {
      if (name === "process") {
        this.$refs.processNodes.getWorkGraph();
      }
      if (name === "work") {
        this.getWorkticketProcess();
      }
    },
    getWorkticketProcess() {
      getWorkticketProcess(this.id).then(res => {
        this.list = (Array.isArray(res) ? res : []).map(item => {
          item.isShowAllDesc = false;
          if (item.current === 1) {
            item.cuserName = item.operatorList
              ? item.operatorList.join(",")
              : "";
          }
          return item;
        });
      });
    },
    showDescClick(show, idx) {
      this.list[idx].isShowAllDesc = show;
    },
    showSignature(fileId) {
      esign.showImage(fileUrl + fileId);
    },
    workHeadPerson(arr) {
      const personType = ["311", "301", "3201"];
      return arr.filter(i => personType.includes(i.workerType));
    },
    approverPerson(arr) {
      const personType = ["312", "302", "3202"];
      return arr.filter(i => personType.includes(i.workerType));
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.inactive-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #c3c6d0;
  border-radius: 50%;
}
.van-step--finish .inactive-icon {
  background-color: $--color-primary;
}
.active-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #ffffff;
  border: 2px solid $--color-primary;
  border-radius: 50%;
}
</style>

<style lang="scss" scoped>
.view-workticket-process {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .van-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    ::v-deep {
      .van-tabs__content {
        flex: 1 0 0;
        overflow-y: scroll;
      }
    }
  }
}
.inline-block {
  display: inline-block;
}

.steps-box {
  padding: 10px;
  box-sizing: border-box;
}
.process-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  .process-box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .process-node-diamond {
    display: flex;
    width: 235px;
    flex-direction: row;
    justify-content: space-between;
  }
  .process-arrow-polyline {
    display: flex;
    justify-content: space-between;
    width: 164px;
  }
  .process-arrow-double {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .arrow-start,
    .arrow-end {
      position: relative;
      display: block;
      width: 38px;
      height: 10px;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #c7c9d0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
    .arrow-start {
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-right-width: 6px;
        border-right-color: #c7c9d0;
        left: -5px;
        top: 0;
      }
    }
    .arrow-end {
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-left-color: #c7c9d0;
        border-left-width: 6px;
        right: -5px;
        top: 0;
      }
    }
  }
}

.process-item {
  font-size: 12px;
  color: $--color-gray-item-text;

  .title-line {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    color: $--color-black-title;

    .title {
      flex-grow: 1;
    }
  }

  .initial-height {
    max-height: initial !important;
  }

  .qianming {
    font-size: 16px;
    color: #1676ff;
    margin: 0 0 0 8px;
  }

  .modify-person-box {
    font-size: 12px;
    font-weight: 400;
    color: #8c8f97;
    line-height: 18px;
    margin: 0 0 8px;
    // span {
    //   vertical-align: middle;
    // }
    .name-box {
      display: inline-block;
      margin: 0 8px 0 0;
      // vertical-align: initial;
    }
    .name-icon-box {
      display: inline-block;
      margin: 0 12px 0 0;
      i {
        margin: 0 0 0 4px;
        // vertical-align: middle;
      }
    }
  }

  .desc {
    max-height: 54px;
    font-size: 12px;
    font-weight: 400;
    color: #8c8f97;
    line-height: 18px;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    word-break: break-all;
    .ellipsis-box {
      text-align: right;
      width: 54px;
      position: absolute;
      right: 0px;
      bottom: 0;
      background: #fff;
      background: -webkit-gradient(
        linear,
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
      background: -moz-linear-gradient(
        to right,
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
      background: -o-linear-gradient(
        to right,
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
      background: -ms-linear-gradient(
        to right,
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
      background: linear-gradient(
        270deg,
        #ffffff,
        #ffffff 46%,
        rgba(255, 255, 255, 0)
      );
    }
    .after {
      height: 18px;
      width: 54px;
      position: absolute;
      right: 0px;
      background-color: white;
    }
  }

  .show-all-btn {
    color: #1676ff;
  }
  .packup-box {
    text-align: right;
    margin-top: -8px;
  }

  .icon-item {
    display: inline-block;
    margin-right: 20px;

    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      vertical-align: top;
      margin-right: 5px;
      background-size: cover;
    }
  }
}
</style>
