var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-nav-bar view-workticket-process"},[_c('WorkticketHeader',{attrs:{"title":"流程"}}),_c('van-tabs',{on:{"change":_vm.tabsChange},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('van-tab',{attrs:{"title":"流程图","name":"process"}},[_c('process-nodes',{ref:"processNodes",attrs:{"ticket-id":_vm.id}})],1),_c('van-tab',{attrs:{"title":"作业记录","name":"work"}},[_c('div',{staticClass:"steps-box"},[_c('van-steps',{attrs:{"direction":"vertical","active":_vm.active,"active-color":"#1676FF"}},_vm._l((_vm.list),function(item,index){return _c('van-step',{key:index,scopedSlots:_vm._u([{key:"inactive-icon",fn:function(){return [_c('i',{class:item.current === 1 ? 'active-icon' : 'inactive-icon'})]},proxy:true},{key:"active-icon",fn:function(){return [_c('i',{staticClass:"active-icon"})]},proxy:true}],null,true)},[_c('div',{staticClass:"process-item"},[_c('div',{staticClass:"title-line"},[(item.operation === '转审')?_c('span',{staticClass:"right"},[_vm._v(_vm._s(item.operation)+_vm._s(("" + (item.nodeName ? "-" + item.nodeName : ""))))]):(
                    item.operation
                      ? item.operation.slice(0, 2) === '审批'
                      : ''
                  )?_c('span',{staticClass:"right"},[_vm._v(_vm._s(item.operation)+" "+_vm._s(item.specialName ? ("(" + (item.specialName) + ")") : ""))]):_c('span',{staticClass:"right"},[_vm._v(_vm._s(item.operation))])]),(item.operation === '转审')?_c('div',{staticClass:"modify-person-box"},[_c('div',{staticClass:"inline-block"},[_vm._v(" 转审前人员： "),_c('span',{staticClass:"name-box"},[_vm._v(" "+_vm._s(item.beforeTransferList ? item.beforeTransferList.join(" ") : "")+" ")]),_vm._v(" 转审后人员： "),_c('span',{staticClass:"name-box"},[_vm._v(" "+_vm._s(item.afterTransferList ? item.afterTransferList.join(" ") : "")+" ")])]),(_vm.countersignObj[item.countersign])?_c('div',[_c('span',[_vm._v("审批方式：")]),_c('span',{staticClass:"name-icon-box"},[_vm._v(" "+_vm._s(_vm.countersignObj[item.countersign])+" ")])]):_vm._e()]):_vm._e(),(item.nodeName)?_c('div',[_c('span',[_vm._v("审批节点：")]),_c('span',{staticClass:"name-icon-box"},[_vm._v(" "+_vm._s(item.nodeName)+" ")])]):_vm._e(),(
                  item.operation ? item.operation.slice(0, 2) === '审批' : ''
                )?_c('div',{staticClass:"modify-person-box"},[(_vm.countersignObj[item.countersign])?_c('div',[_c('span',[_vm._v("审批方式：")]),_c('span',{staticClass:"name-icon-box"},[_vm._v(" "+_vm._s(_vm.countersignObj[item.countersign])+" ")])]):_vm._e(),(item.current !== 1)?_c('div',[_c('span',[_vm._v("审批人：")]),_c('span',{staticClass:"name-icon-box",on:{"click":function($event){return _vm.showSignature(item.signatureFileId)}}},[_c('span',[_vm._v(_vm._s(item.cuserName))]),(item.operation !== '审批-退回')?_c('i',{staticClass:"iconfont iconfont-icoqianming qianming"}):_vm._e()])]):_vm._e()]):_vm._e(),(
                  Array.isArray(item.modifyList) && item.modifyList.length
                )?_c('div',{staticClass:"modify-person-box"},[_c('div',{staticClass:"inline-block"},[_vm._v(" 变更前人员： "),_vm._l((item.modifyList.filter(
                      function (item) { return !item.current; }
                    )),function(item){return _c('span',{key:item.id,staticClass:"name-box"},[_vm._v(" "+_vm._s(item.name)+" ")])}),_vm._v(" 变更后人员： "),_vm._l((item.modifyList.filter(
                      function (item) { return item.current; }
                    )),function(item){return _c('span',{key:item.id,staticClass:"name-box"},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]):_vm._e(),(
                  Array.isArray(item.deliverList) && item.deliverList.length
                )?_c('div',{staticClass:"modify-person-box"},[_c('div',[_c('span',[_vm._v("验收人：")]),_vm._l((item.deliverList),function(item){return _c('span',{key:item.id,staticClass:"name-icon-box",on:{"click":function($event){return _vm.showSignature(item.fileId)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"iconfont iconfont-icoqianming qianming"})])})],2)]):_vm._e(),(
                  Array.isArray(item.workerList) && item.workerList.length
                )?_c('div',{staticClass:"modify-person-box"},[(_vm.typeMap[item.operationType])?[_c('div',[(item.operation === '继续作业')?_c('span',[_vm._v(" 确认人： ")]):(item.workerList[0].workerType === '40')?_c('span',[_vm._v(" 交出人： ")]):_c('span',[_vm._v(_vm._s(((_vm.typeMap[item.operationType]) + "人：")))]),_vm._l((item.workerList),function(item){return _c('span',{key:item.id,staticClass:"name-icon-box",on:{"click":function($event){return _vm.showSignature(item.fileId)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"iconfont iconfont-icoqianming qianming"})])})],2)]:[_c('div',[(_vm.workHeadPerson(item.workerList))?_c('div',[_c('span',[_vm._v("作业负责人：")]),_vm._l((_vm.workHeadPerson(item.workerList)),function(item){return _c('span',{key:item.id,staticClass:"name-icon-box",on:{"click":function($event){return _vm.showSignature(item.fileId)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"iconfont iconfont-icoqianming qianming"})])})],2):_vm._e(),(_vm.approverPerson(item.workerList))?_c('div',[_c('span',[_vm._v("批准人：")]),_vm._l((_vm.approverPerson(item.workerList)),function(item){return _c('span',{key:item.id,staticClass:"name-icon-box",on:{"click":function($event){return _vm.showSignature(item.fileId)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"iconfont iconfont-icoqianming qianming"})])})],2):_vm._e()])]],2):_vm._e(),(
                  Array.isArray(item.closerList) && item.closerList.length
                )?_c('div',{staticClass:"modify-person-box"},[_c('div',[_c('span',[_vm._v("关闭人：")]),_vm._l((item.closerList),function(item){return _c('span',{key:item.id,staticClass:"name-icon-box",on:{"click":function($event){return _vm.showSignature(item.fileId)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('i',{staticClass:"iconfont iconfont-icoqianming qianming"})])})],2)]):_vm._e(),_c('div',{staticClass:"desc",class:{ 'initial-height': item.isShowAllDesc }},[_vm._v(" "+_vm._s(item.remark)+" "),_c('span',{staticClass:"ellipsis-box show-all-btn",on:{"click":function($event){return _vm.showDescClick(true, index)}}},[_vm._v("展开")]),_c('span',{staticClass:"after"})]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.isShowAllDesc),expression:"item.isShowAllDesc"}],staticClass:"packup-box"},[_c('span',{staticClass:"show-all-btn",on:{"click":function($event){return _vm.showDescClick(false, index)}}},[_vm._v("收起")])]),(item.delayTimeInfo)?_c('div',[_vm._v(" 从"+_vm._s(item.delayTimeInfo.start)+" 延期至 "+_vm._s(item.delayTimeInfo.end)+" ")]):_vm._e(),_c('p',{staticClass:"time"},[_c('span',{staticClass:"icon-item"},[_c('i',{style:(("background-image: url(" + (require('@/assets/images/workticket-item-5.png')) + ")"))}),_vm._v(_vm._s(item.cuserName))]),(item.ctime)?_c('span',{staticClass:"icon-item"},[_c('i',{style:(("background-image: url(" + (require('@/assets/images/workticket-item-3.png')) + ")"))}),_vm._v(_vm._s(item.ctime))]):_vm._e()])])])}),1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }