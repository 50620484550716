<template>
  <div>
    <div
      v-if="type === 'rect'"
      class="process-node"
      :class="[type, category]"
      @click="personClick"
    >
      <van-icon name="manager" />
      <span class="node-name">{{ nodeName }}</span>
    </div>
    <div
      v-else-if="type === 'circle'"
      class="process-node"
      :class="[type, category]"
    >
      <span class="node-name">{{ nodeName }}</span>
    </div>
    <div
      v-else-if="type == 'diamond'"
      class="process-node"
      :class="[type, category]"
      @click="personClick"
    >
      <div class="diamond-bg" :class="[type, category]"></div>
      <span class="diamond-text" :class="[type, category]">
        <van-icon name="manager" />
        <span class="node-name">{{ nodeName }}</span>
      </span>
    </div>
    <van-popup v-model="workerDialog.showDialog" class="worker-popup" closeable>
      <div class="worker-popup-title">{{ workerDialog.dialogTitle }}</div>
      <div class="worker-popup-text">
        <span class="worker-text">{{ workerDialog.label }}</span>
        <span class="worker-name">{{ workerDialog.value }}</span>
      </div>
    </van-popup>
    <van-popup
      v-model="approveDialog.showDialog"
      class="worker-approve-popup"
      closeable
    >
      <div class="worker-popup-title">{{ approveDialog.dialogTitle }}</div>
      <div class="worker-popup-text">
        <div
          v-for="item in approveDialog.data"
          :key="item.specialId"
          class="worker-popup-item"
        >
          <div class="special-name">{{ item.specialName }}</div>
          <div class="special-box">
            <div
              v-for="(child, index) in item.approveNodeVOList"
              :key="'child-' + index"
              class="special-item"
            >
              <div class="special-item-node">
                {{ child.name || "--" }}
                <span class="sign">{{
                  child.countersign === 1 ? "会签" : "或签"
                }}</span>
              </div>
              <div>
                <span
                  v-for="n in child.approverNameList"
                  :key="n"
                  class="worker-name"
                  >{{ n }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  workticketApprove as getGraphApprove,
  workticketWorker as getGraphPerson
} from "@/api/workticket";
import { PROCESS_LIST } from "../utils/constant";

export default {
  name: "ProcessNode",
  props: {
    nodeName: {
      type: String,
      default: "节点名称"
    },
    type: {
      type: String,
      default: "rect"
    },
    category: {
      type: String,
      default: ""
    },
    ticketId: {
      type: String
    }
  },
  data() {
    return {
      workerDialog: {
        showDialog: false,
        dialogTitle: "标题",
        label: "批准人:",
        value: "张三"
      },
      approveDialog: {
        showDialog: false,
        dialogTitle: "标题",
        data: [
          {
            specialId: "", // 特殊作业id
            specialName: "作业票1", // 特殊作业名称
            approveNodeVOList: [
              {
                approverList: [], // 审批人id ,String
                approverNameList: ["张三", "李四"], // 审批人名称 ,String
                countersign: 0, // 是否会签1是0否
                name: "节点1" // 节点名称
              }
            ] // 审批节点 ,ApproveNodeVO
          }
        ]
      }
    };
  },
  methods: {
    async personClick() {
      const curNode = PROCESS_LIST().find(
        item => item.nodeName === this.nodeName
      );
      if (curNode.nodeName === "取消") return;
      if ((!curNode || !curNode.personType) && curNode.nodeName !== "审批中") {
        this.$toast("人员类型错误!");
        return;
      }
      if (curNode.nodeName !== "审批中") {
        const person = await getGraphPerson(curNode.personType, this.ticketId);
        if ([2, 5].includes(curNode.personType) && !person.length) {
          this.$toast("人员还未确定，安全分析结束后可进行查看");
          return;
        }
        this.workerDialog.dialogTitle = curNode.nodeName;
        this.workerDialog.label = `${curNode.personName}：`;
        this.workerDialog.value = person.length
          ? person.map(a => a.name).join("、")
          : "无";
        this.workerDialog.showDialog = true;
        return;
      }
      const res = await getGraphApprove(this.ticketId);
      this.approveDialog.dialogTitle = curNode.nodeName;
      this.approveDialog.data = res;
      this.approveDialog.showDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.process-node {
  border: 1px solid rgba(22, 118, 255, 0.5);
  background-color: #dcebff;
  text-align: center;
  color: #2e2e4d;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  &.current {
    background-color: #1676ff;
    color: #fff;
    &.diamond {
      .diamond-bg {
        background-color: #1676ff;
        color: #fff;
      }
      .diamond-text {
        .van-icon {
          color: #fff;
        }
      }
    }
    &.rect .van-icon {
      color: #fff;
    }
  }
  &.normal {
    background-color: #f6f7fa;
    border: 1px solid #c7c9d0;
    &.diamond {
      .diamond-bg {
        background-color: #f6f7fa;
        border: 1px solid #c7c9d0;
      }
    }
  }
  &.rect {
    width: 228px;
    height: 48px;
    border-radius: 4px;
    .van-icon {
      color: #1676ff;
      font-size: 16px;
    }
    .node-name {
      margin-left: 6px;
    }
  }
  &.circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  &.diamond {
    position: relative;
    border: none;
    background-color: transparent;
    padding: 6px 0;
    .diamond-bg {
      width: 70px;
      height: 70px;
      transform: rotateZ(33deg) skew(-20deg);
      text-align: center;
      border: 1px solid rgba(22, 118, 255, 0.5);
      background-color: #dcebff;
      border-radius: 2px;
    }
    .diamond-text {
      position: absolute;
      left: 50%;
      top: 50%;
      white-space: nowrap;
      transform: translate3d(-50%, -50%, 0);
      .van-icon {
        color: #1676ff;
        font-size: 16px;
      }
      .node-name {
        position: relative;
        margin-left: 6px;
        top: -2px;
      }
    }
  }
}
.worker-popup {
  width: 315px;
  border-radius: 4px;
  background-color: #fff;
  min-height: 124px;
  padding: 18px 37px;
  box-sizing: border-box;
  &-title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  &-text {
    margin-top: 16px;
    font-size: 14px;
    color: #2e2e4d;
  }
}
.worker-approve-popup {
  width: 315px;
  padding: 16px 0;
  max-height: 466px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  .worker-popup-title {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
  .worker-popup-text {
    padding: 0 38px;
    margin-top: 16px;
    flex: 1;
    overflow-y: auto;
    .worker-popup-item {
      margin-bottom: 16px;
      .special-name {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .special-box {
        background-color: rgba(22, 118, 255, 0.05);
        .special-item {
          margin-bottom: 16px;
          padding: 8px;
          .special-item-node {
            color: #8c8f97;
            margin-bottom: 8px;
            .sign {
              margin-left: 16px;
            }
          }
          .worker-name {
            display: inline-block;
            margin-right: 14px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
</style>
