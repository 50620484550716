<template>
  <div v-if="loaded" class="process-nodes">
    <process-node
      :category="PROCESS_LIST[0].category"
      :type="PROCESS_LIST[0].type"
      :node-name="PROCESS_LIST[0].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[0].hasNext"
      :type="PROCESS_LIST[0].lineType"
      :arrow-status="PROCESS_LIST[0].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[1].isShow"
      :category="PROCESS_LIST[1].category"
      :type="PROCESS_LIST[1].type"
      :node-name="PROCESS_LIST[1].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[1].hasNext"
      :type="PROCESS_LIST[1].lineType"
      :arrow-status="PROCESS_LIST[1].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[2].isShow"
      ref="cancel-node"
      class="cancel-flow"
      :category="PROCESS_LIST[2].category"
      :type="PROCESS_LIST[2].type"
      :node-name="PROCESS_LIST[2].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[2].hasNext"
      ref="cancel-arrow"
      :type="PROCESS_LIST[2].lineType"
      :arrow-status="PROCESS_LIST[2].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[3].isShow"
      :category="PROCESS_LIST[3].category"
      :type="PROCESS_LIST[3].type"
      :node-name="PROCESS_LIST[3].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[3].hasNext"
      :type="PROCESS_LIST[3].lineType"
      :arrow-status="PROCESS_LIST[3].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[4].isShow"
      :category="PROCESS_LIST[4].category"
      :type="PROCESS_LIST[4].type"
      :node-name="PROCESS_LIST[4].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[4].hasNext"
      :type="PROCESS_LIST[4].lineType"
      :arrow-status="PROCESS_LIST[4].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[5].isShow"
      :category="PROCESS_LIST[5].category"
      :type="PROCESS_LIST[5].type"
      :node-name="PROCESS_LIST[5].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[5].hasNext"
      :type="PROCESS_LIST[5].lineType"
      :arrow-status="PROCESS_LIST[5].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[6].isShow"
      :category="PROCESS_LIST[6].category"
      :type="PROCESS_LIST[6].type"
      :node-name="PROCESS_LIST[6].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[6].hasNext"
      :type="PROCESS_LIST[6].lineType"
      :arrow-status="PROCESS_LIST[6].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[14].isShow"
      :category="PROCESS_LIST[14].category"
      :type="PROCESS_LIST[14].type"
      :node-name="PROCESS_LIST[14].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[14].hasNext"
      :type="PROCESS_LIST[14].lineType"
      :arrow-status="PROCESS_LIST[14].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[15].isShow"
      :category="PROCESS_LIST[15].category"
      :type="PROCESS_LIST[15].type"
      :node-name="PROCESS_LIST[15].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[15].hasNext"
      :type="PROCESS_LIST[15].lineType"
      :arrow-status="PROCESS_LIST[15].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[16].isShow"
      :category="PROCESS_LIST[16].category"
      :type="PROCESS_LIST[16].type"
      :node-name="PROCESS_LIST[16].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[16].hasNext"
      :type="PROCESS_LIST[16].lineType"
      :arrow-status="PROCESS_LIST[16].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[17].isShow"
      :category="PROCESS_LIST[17].category"
      :type="PROCESS_LIST[17].type"
      :node-name="PROCESS_LIST[17].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[17].hasNext"
      :type="PROCESS_LIST[17].lineType"
      :arrow-status="PROCESS_LIST[17].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[18].isShow"
      :category="PROCESS_LIST[18].category"
      :type="PROCESS_LIST[18].type"
      :node-name="PROCESS_LIST[18].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[18].hasNext"
      :type="PROCESS_LIST[18].lineType"
      :arrow-status="PROCESS_LIST[18].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[19].isShow"
      :category="PROCESS_LIST[19].category"
      :type="PROCESS_LIST[19].type"
      :node-name="PROCESS_LIST[19].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[19].hasNext"
      :type="PROCESS_LIST[19].lineType"
      :arrow-status="PROCESS_LIST[19].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[7].isShow"
      :category="PROCESS_LIST[7].category"
      :type="PROCESS_LIST[7].type"
      :node-name="PROCESS_LIST[7].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[7].hasNext"
      :type="PROCESS_LIST[7].lineType"
      :arrow-status="PROCESS_LIST[7].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[8].isShow"
      :category="PROCESS_LIST[8].category"
      :type="PROCESS_LIST[8].type"
      :node-name="PROCESS_LIST[8].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[8].hasNext"
      :type="PROCESS_LIST[8].lineType"
      :arrow-status="PROCESS_LIST[8].arrowStatus"
    />
    <div v-if="!data.cancel" class="process-node-diamond">
      <process-node
        :category="PROCESS_LIST[9].category"
        :type="PROCESS_LIST[9].type"
        :node-name="PROCESS_LIST[9].nodeName"
        :ticket-id="ticketId"
      />
      <div class="process-arrow-double">
        <i
          class="arrow-end"
          :class="{
            active: data.cross || data.preIndex === 9 || data.curIndex === 9
          }"
        ></i>
        <i class="arrow-start" :class="{ active: data.cross }"></i>
      </div>
      <process-node
        :category="PROCESS_LIST[10].category"
        :type="PROCESS_LIST[10].type"
        :node-name="PROCESS_LIST[10].nodeName"
        :ticket-id="ticketId"
      />
    </div>
    <div class="process-arrow-polyline">
      <process-arrow
        v-if="PROCESS_LIST[9].hasNext"
        class="left"
        :type="PROCESS_LIST[9].lineType"
        :arrow-status="PROCESS_LIST[9].arrowStatus"
      />
      <process-arrow
        v-if="PROCESS_LIST[10].hasNext"
        class="right"
        :type="PROCESS_LIST[10].lineType"
        :arrow-status="PROCESS_LIST[10].arrowStatus"
      />
    </div>
    <process-node
      v-if="PROCESS_LIST[11].isShow"
      :category="PROCESS_LIST[11].category"
      :type="PROCESS_LIST[11].type"
      :node-name="PROCESS_LIST[11].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[11].hasNext"
      :type="PROCESS_LIST[11].lineType"
      :arrow-status="PROCESS_LIST[11].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[12].isShow"
      :category="PROCESS_LIST[12].category"
      :type="PROCESS_LIST[12].type"
      :node-name="PROCESS_LIST[12].nodeName"
      :ticket-id="ticketId"
    />
    <process-arrow
      v-if="PROCESS_LIST[12].hasNext"
      :type="PROCESS_LIST[12].lineType"
      :arrow-status="PROCESS_LIST[12].arrowStatus"
    />
    <process-node
      v-if="PROCESS_LIST[13].isShow"
      :category="PROCESS_LIST[13].category"
      :type="PROCESS_LIST[13].type"
      :node-name="PROCESS_LIST[13].nodeName"
      :ticket-id="ticketId"
    />
  </div>
</template>

<script>
import ProcessNode from "./ProcessNode";
import ProcessArrow from "./ProcessArrow";
import { PROCESS_LIST } from "../utils/constant";
import {
  workticketApprove,
  workticketGraph,
  workticketWorker
} from "@/api/workticket";
export default {
  components: {
    ProcessNode,
    ProcessArrow
  },
  props: {
    ticketId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loaded: false,
      PROCESS_LIST: PROCESS_LIST(),
      data: {}
      // data: {
      //   curIndex: 0, // 当前节点序号
      //   gas: 0, // 是否有气体检测
      //   preIndex: 0, // 上一个节点序号
      //   cross: 0, // 是否交叉【作业中、暂停中】
      //   cancel: 0 // 是否有取消,0:没有,1:有
      // }
    };
  },
  created() {
    this.getWorkGraph();
  },
  methods: {
    dataConfig() {
      const { data } = this;
      const max = data.curIndex + 1;
      let list = this.PROCESS_LIST.map((item, idx) => {
        const nodeName = item.nodeName;
        // 实际节点多一个申请，数据序号要加1，小于当前节点的，arrowStatus设为active，category设为空
        // 当序号大于菱形区域10，则preIndex为8或9，且同时cross标注是否交叉。
        if (idx < max) {
          item.category = "";
          if (idx !== 9) {
            item.arrowStatus = "active";
          }

          if ((idx === 9 || idx === 10) && data.curIndex >= 10) {
            if (idx === data.preIndex + 1) {
              item.arrowStatus = "active";
            } else {
              if (!data.cross) {
                item.category = "normal";
              }
              item.arrowStatus = "";
            }
            if (idx === 9 && data.preIndex + 1 === 10) {
              item.category = "";
            }
          }
        } else if (idx === max) {
          item.category = "current";
          // item.arrowStatus = "active";
        }
        if (!data.gas && nodeName === "待气体检测") {
          item.isShow = false;
          item.hasNext = false;
        }
        if (!data.cancel && nodeName === "取消") {
          item.isShow = false;
          item.hasNext = false;
        } else if (data.cancel) {
          if (data.preIndex > 0) {
            if (idx <= data.preIndex + 1) {
              item.arrowStatus = idx === data.preIndex + 1 ? "" : "active";
              item.category = idx === max ? "current" : "";
              item.isShow = true;
              item.hasNext = idx === data.preIndex + 1 ? false : true;
            } else {
              item.isShow = false;
              item.hasNext = false;
            }
          } else if (idx >= 2) {
            item.isShow = idx > 2 ? false : true;
            item.hasNext = false;
          }
        }
        return item;
      });
      if (data.curIndex === 8 && (data.preIndex === 9 || data.cross)) {
        list[10].category = "";
      }

      list = this.cancelReset(list);
      this.PROCESS_LIST = list;
    },
    equipmentSafetyFlow() {
      // 设备检修安全作业流程图
      // 隐藏原节点：待安全分析、安全分析中、待气体检测
      // 显示自定义节点：待工艺安全分析、工艺安全分析中、工艺安全分析中、待检修安全分析、检修安全分析中、待检修安全交底
      const { data } = this;
      const originNodes = [3, 4, 6]; // 原节点
      const customNodes = [14, 15, 16, 17, 18, 19]; // 自定义节点
      const prevNode = 1; // 自定义节点前的节点
      const nextNode = 7; // 自定义节点后的节点
      let max = data.curIndex + 1;
      if (customNodes.includes(data.curIndex)) {
        max = nextNode;
      }
      let list = this.PROCESS_LIST.map((item, idx) => {
        const nodeName = item.nodeName;
        // 实际节点多一个申请，数据序号要加1，小于当前节点的，arrowStatus设为active，category设为空
        // 当序号大于菱形区域10，则preIndex为8或9，且同时cross标注是否交叉。
        if (idx < max) {
          item.category = "";
          if (idx !== 9) {
            item.arrowStatus = "active";
          }

          if ((idx === 9 || idx === 10) && data.curIndex >= 10) {
            if (idx === data.preIndex + 1) {
              item.arrowStatus = "active";
            } else {
              if (!data.cross) {
                item.category = "normal";
              }
              item.arrowStatus = "";
            }
            if (idx === 9 && data.preIndex + 1 === 10) {
              item.category = "";
            }
          }
        }

        if (!data.gas && nodeName === "待气体检测") {
          item.isShow = false;
          item.hasNext = false;
        }
        if (!data.cancel && nodeName === "取消") {
          item.isShow = false;
          item.hasNext = false;
        } else if (data.cancel) {
          if (data.preIndex > 0) {
            if (idx <= data.preIndex + 1) {
              item.arrowStatus = idx === data.preIndex + 1 ? "" : "active";
              item.category = idx === max ? "current" : "";
              item.isShow = true;
              item.hasNext = idx === data.preIndex + 1 ? false : true;
            } else {
              item.isShow = false;
              item.hasNext = false;
            }
          } else if (idx >= 2) {
            item.isShow = idx > 2 ? false : true;
            item.hasNext = false;
          }
        }
        if (!data.cancel) {
          if (originNodes.includes(idx)) {
            item.isShow = false;
            item.hasNext = false;
          } else if (customNodes.includes(idx)) {
            item.isShow = true;
            item.hasNext = true;
          }
        }
        return item;
      });
      if (data.curIndex === 8 && (data.preIndex === 9 || data.cross)) {
        list[10].category = "";
      }
      // 激活自定义节点
      if (customNodes.includes(data.curIndex)) {
        for (let i = 0; i < customNodes.length; i++) {
          if (customNodes[i] > data.curIndex) break;
          list[customNodes[i]].category = "";
          list[customNodes[i]].arrowStatus = "active";
        }
      } else if (data.curIndex + 1 >= nextNode) {
        for (let i = 0; i < customNodes.length; i++) {
          list[customNodes[i]].category = "";
          list[customNodes[i]].arrowStatus = "active";
        }
      }
      // 选中当前节点
      if (data.cancel) {
        list[2].isShow = true;
        list[2].category = "current";
      } else if (data.curIndex === prevNode) {
        list[customNodes[0]].category = "current";
      } else if (data.curIndex === customNodes[customNodes.length - 1]) {
        list[nextNode].category = "current";
      } else {
        list[data.curIndex + 1].category = "current";
      }

      list = this.cancelReset(list);
      this.PROCESS_LIST = list;
    },
    cancelReset(list) {
      // 取消状态，并且取消不在最下面的时候（当前取消位置是2），把取消放到最下面
      const cancelIndex = list.findIndex(item => item.nodeName === "取消");
      if (this.data.cancel && this.data.preIndex > cancelIndex) {
        const cancelItem = list.splice(cancelIndex, 1)[0];
        cancelItem.hasNext = false;
        // 最后一条数据加上下箭头
        if (list[list.length - 1]) {
          list[list.length - 1].hasNext = true;
          list[list.length - 1].arrowStatus = "active";
        }
        list.push(cancelItem);
      }
      return list;
    },
    // 获取整张流程图信息
    getWorkGraph() {
      workticketGraph(this.ticketId)
        .then(res => {
          this.data = res;
          if (res.isEquipmentSafety) {
            this.equipmentSafetyFlow();
          } else {
            this.dataConfig();
          }
          this.loaded = true;
        })
        .catch(e => {
          this.loaded = false;
          this.$toast("获取失败, 请重试");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.process-nodes-loading {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  height: 100%;
}
.process-nodes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  .process-box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .process-node-diamond {
    display: flex;
    width: 235px;
    flex-direction: row;
    justify-content: space-between;
  }
  .process-arrow-polyline {
    display: flex;
    justify-content: space-between;
    width: 164px;
  }
  .process-arrow-double {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .arrow-start,
    .arrow-end {
      position: relative;
      display: block;
      width: 38px;
      height: 10px;
      &.active {
        &::before {
          background-color: #1676ff;
        }
      }
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #c7c9d0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
    .arrow-start {
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-right-width: 6px;
        border-right-color: #c7c9d0;
        left: -5px;
        top: 0;
      }
      &.active {
        &::after {
          border-right-color: #1676ff;
        }
      }
    }
    .arrow-end {
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-left-color: #c7c9d0;
        border-left-width: 6px;
        right: -5px;
        top: 0;
      }
      &.active {
        &::after {
          border-left-color: #1676ff;
        }
      }
    }
  }
}
</style>
